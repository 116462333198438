<template>
  <b-overlay :show="loading">
    <div class="section-classroom-detail">
      <div v-if="submissions.length">
        <div v-if="!taskId || showDropdown" class="task-list">
          <b-dropdown class="luv-dropdown task-dropdown" :text="filter.label" block>
            <b-dropdown-item v-for="(option, index) in options" :key="index" :value="option.value"
                             @click="handleDropdown(option)">
              {{ option.label }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <back-button v-else text="Back to Task"/>
        <div class="lazy-list">
          <LazyList :data="submissions"
                    :itemsPerRender="1"
                    containerClasses="list"
                    defaultLoadingColor="#222"
                    :show-message-no-uploaded-videos="isSmallGroupType && !partnerGroupSubmissions.length"
                    @click-all-videos="onClickAllVideos">
            <template v-slot="{item}">
              <ClassroomDetailItem @close-flag-inappropriate-modal="handleFlagInappropriateModal"
                                   @send-message="saveSubmissionValue"
                                   :ref="`submission-${item.id}`"
                                   :submission="item"
                                   :disable-button-respond="item.disable"
                                   :hidden-actions="hasMyResponse(item.my_responses)"/>
            </template>
          </LazyList>

        </div>
        <TextRespondModal :value="submissionsValue" @load-classroom="onLoadClassroom"/>
      </div>
      <div v-if="!loading && submissions.length === 0" class="nothing-area">
        <div class="nothing-icon"></div>
        <div class="nothing-title">NO UPLOADED VIDEOS</div>
        <div class="nothing-text" v-if="isSmallGroupType">Your partner group has not yet created their first video</div>
        <div class="nothing-text" v-else>Your global partner has not uploaded any videos yet, check back again soon</div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import BackButton from '@/core/components/BackButton'
import ClassroomDetailItem from '../../components/MyClassrooms/ClassroomDetail/ClassroomDetailItem'
import TextRespondModal from '../../components/MyClassrooms/ClassroomDetail/TextRespondModal'
import { ClassroomService } from '@/core/services'
import LazyList from '@/core/components/LazyList/LazyList'
import { CourseMixins } from '@/modules/mixins'

export default {
  name: 'ClassroomDetailPage',
  components: { ClassroomDetailItem, TextRespondModal, BackButton, LazyList },
  mixins: [CourseMixins],
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: false
    },
    showDropdown: {
      type: Boolean,
      required: false
    }
  },
  data: function () {
    return {
      filter: {
        value: '',
        label: 'ALL'
      },
      options: [
        {
          value: '',
          label: 'ALL'
        },
        {
          value: 'unwatched',
          label: 'UNWATCHED'
        }
      ],
      submissions: [],
      loading: true,
      submissionsValue: null,
      interval: 0,
      partnerGroupSubmissions: []
    }
  },
  async created () {
    await this.getClassroom(this.classroomId)

    await this.init().then(() => {
      this.interval = setInterval(() => {
        this.updateSubmissionsList()
      }, 15000)
    })

    if (this.showDropdown) {
      this.options.map((opt) => {
        if (opt.value === this.taskId) {
          this.filter = opt
        }
      })
    }
  },
  methods: {
    handleDropdown (option) {
      this.filter = option
      this.updateTaskFilter(this.filter.value)
    },
    onClickAllVideos () {
      this.filter = {
        value: '',
        label: 'ALL'
      }
      this.updateTaskFilter(this.filter.value)
    },
    hasMyResponse (myResponses = []) {
      if (typeof myResponses === 'object') {
        return Object.keys(myResponses).length > 0
      } else {
        return myResponses.length > 0
      }
    },
    async enableResponseButtons () {
      if (this.isSmallGroupType) {
        // we block videos from non-associated groups
        this.submissions = this.submissions.map(({ ...submission }) => {
          const partnerSubmission = this.partnerGroupSubmissions.find(partnerSubmission => submission.id === partnerSubmission.id)
          submission.disable = !partnerSubmission
          return submission
        })

        // videos with response
        const submissionsWithResponse = await this.submissions.filter((submission) => {
          if (typeof submission.my_responses === 'object') {
            return Object.keys(submission.my_responses).length !== 0
          } else {
            return submission.my_responses.length !== 0
          }
        })

        // We allow you to answer videos from other groups of the same task as the videos already answered
        if (submissionsWithResponse) {
          this.submissions.forEach(submission => {
            const submissionResp = submissionsWithResponse.find(submissionResp => submission.class_task_id === submissionResp.class_task_id)
            if (submissionResp) {
              submission.disable = false
            }
          })
        }
      } else {
        const taskResponses = {}
        const unansweredSubmissions = await this.submissions.filter((submission) => {
          if (typeof submission.my_responses === 'object') {
            return Object.keys(submission.my_responses).length === 0
          } else {
            return submission.my_responses.length === 0
          }
        })
        await unansweredSubmissions.map(submission => {
          const { class_task_id: taskId, responses } = submission
          const hasResponses = responses.length > 0
          if (!(taskId in taskResponses)) {
            taskResponses[taskId] = hasResponses
          } else {
            taskResponses[taskId] = taskResponses[taskId] && hasResponses
          }
        })
        this.submissions.forEach(submission => {
          submission.disable = (!taskResponses[submission.class_task_id] && submission.responses.length > 0) && !(this.hasMyResponse(submission.my_responses))
        })
      }
    },
    async init () {
      this.loading = true

      if (!this.classroomId) {
        await this.$router.push({ name: 'MyClassroomsPage' })
        return
      }

      try {
        if (!this.taskId || this.showDropdown) {
          await this.getTasks()
        }

        const submissions = await this.getSubmissions()
        this.submissions = submissions ?? []

        this.enableResponseButtons()

        if (!this.submissions.length && this.filter.value === 'unwatched') {
          clearInterval(this.interval)
          this.$bvModal.msgBoxOk('Unwatched videos not found').then(() => {
            this.filter = {
              value: '',
              label: 'ALL'
            }
            this.updateTaskFilter(this.filter.value)
          })
        }
      } catch (e) {
        const errorMessage = e.message ?? 'Error while loading classroom partner submissions.'
        console.log(e, errorMessage)
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loading = false
      }
    },
    async updateSubmissionsList () {
      if (this.loading) {
        return
      }

      if (!this.classroomId) {
        await this.$router.push({ name: 'MyClassroomsPage' })
        return
      }

      try {
        const submissions = await this.getSubmissions()

        if (this.loading) {
          return
        }

        submissions.forEach(s => {
          const option = this.submissions.find(o => o.id === s.id)
          if (!option) {
            this.submissions.unshift(s)
          }
        })

        this.enableResponseButtons()
      } catch (e) {
        const errorMessage = e.message ?? 'Error while loading classroom partner submissions.'
        console.log(e, errorMessage)
      }
    },
    async getTasks () {
      const response = await ClassroomService.tasksWithPartnerSubmissions(this.classroomId)

      this.options = [{ value: '', label: 'ALL' }, { value: 'unwatched', label: 'UNWATCHED' }]
      if (response.success) {
        this.tasks = response.data ?? Array.isArray(response.data) ? response.data : []
        this.tasks.forEach(s => {
          const option = this.options.find(o => o.value === s.id)
          if (!option) {
            const newOption = { value: s.id, label: s.task_name, type: 'task' }
            this.options.push(newOption)
          }
        })
      } else {
        throw response.message ?? ''
      }
    },
    async getSubmissions () {
      // eslint-disable-next-line camelcase
      const data = {}
      if (this.filter.type === 'task' || this.taskId) {
        data.class_task_id = this.taskId ? this.taskId : this.filter.value
      }

      if (this.filter.value === 'unwatched') {
        data.unwatched = 1
      }

      // save videos from my partner group in partnerGroupSubmissions
      if (this.isSmallGroupType) {
        const dataSmallGroup = {
          small_group_id: this.classroom?.partner_group_id
        }
        const response = await ClassroomService.getClassroomPartnerSubmissions(this.classroomId, dataSmallGroup)
        this.partnerGroupSubmissions = response.data ?? []
      }

      const response = await ClassroomService.getClassroomPartnerSubmissions(this.classroomId, data)

      if (response.success) {
        return response.data
        // this.submissions = response.data
      } else {
        throw response.message ?? ''
      }
    },
    updateTaskFilter (taskId) {
      const option = this.options.find(o => o.value === taskId)
      if (!option) {
        this.filter = { value: '', label: 'ALL' }
      } else {
        this.filter = option
      }

      clearInterval(this.interval)
      this.init().then(() => {
        this.interval = setInterval(() => {
          this.updateSubmissionsList()
        }, 15000)
      })
    },
    saveSubmissionValue (arg) {
      this.submissionsValue = arg
    },
    onLoadClassroom (arg) {
      if (arg) {
        this.init()
      }
    },
    handleFlagInappropriateModal () {
      this.init()
    }
  },
  watch: {
    classroomId: function () {
      this.init()
    },
    taskId: function (newTaskId) {
      this.updateTaskFilter(newTaskId)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/dropdown.styl"

.section-classroom-detail
  min-height calc(100vh - 100px)

.task-dropdown
  margin 0 55px

.task-list
  margin auto
  height 36px
  text-align center
  background-color color-purple-100

  select
    height 30px
    width 266px
    color #3d3d3d
    font-size 12px
    font-weight bold
    padding-left 5px
    border solid 1px #e8ecee
    border-radius 4px
    margin-top 3px
    text-transform uppercase

.nothing-area
  margin auto
  padding 80px
  text-align center
  max-width 375px
  padding-top 220px

  .nothing-icon
    background url('../../../../assets/images/alert-icon.svg')
    width 100%
    height 80px
    background-repeat no-repeat
    background-position center
    background-size contain
    margin-bottom 10px

  .nothing-title
    font-size 16px
    font-weight bold
    margin-bottom 5px

  .nothing-text
    font-size 14px
</style>

<style lang="stylus">
.layout-main--desktop-authenticate
  .lazy-list
    height calc(100vh - (80px + 50px + 36px))

.layout-main--authenticate
  .lazy-list
    height calc(100vh - (50px + 50px + 36px))
</style>
