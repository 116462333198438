<template>
  <b-row class="submission-row">
    <div class="video-container">
      <video-custom
        :src="submission.url"
        :video_processed="submission.video_processed"
        :track-video-views="true"
        :submission-id="submission.id"
        :classroom-id="classroomId"
        ref="video-custom"/>
    </div>
    <div class="action-container">
      <b-tooltip v-if="!isSmallGroupType"
                 :target="'tooltip-button-response' + submission.id"
                 :disabled="!disableButtonRespond"
                 placement="top">
        There are videos for this task that have not yet received a response. Please respond to those videos first.
      </b-tooltip>
      <div class="actions" :class="{'actions-disabled': hiddenActions || classroomExchangeExpired || disableButtonRespond}">
        <router-link v-if="taskResponseType === 1" class="action-icon" role="link"
                     :id="'tooltip-button-response' + submission.id"
                     :to="goToUploadOrRecordResponsePage(hiddenActions || classroomExchangeExpired || disableButtonRespond)">
          <div class="icon-camera"></div>
          <div class="response-button-text">RESPOND TO <br> {{setUserName(submission.student.full_name)}}</div>
          <div class="response-button-text-mobile">RESPOND TO {{setUserName(submission.student.full_name)}}</div>
        </router-link>
        <div v-else class="action-icon" role="button" @click="onSendMessage">
          <div class="icon-comment"></div>
          <div class="response-button-text">Text Respond</div>
        </div>
      </div>
      <div class="actions-absolute">
        <div class="information-bg">
          <div class="information-container">
            <div class="username">{{ setUserName(submission.student.full_name) }}</div>
            <div class="posted-text">Posted: {{ submission.created_at | dateElapseTime }}</div>
            <div class="country-text">{{ submission.country }}</div>
            <div class="submission-name-text">{{ submission.task.task_name }}</div>
            <div class="container-reponses">
              <div class="submission-responses-count">
                ({{ taskResponses }}
                {{ taskResponses === 1 ? 'response':'responses' }})
              </div>
            </div>
            <div class="flag-inappropriate" v-if="!isTeacher">
              <b-nav-item-dropdown class="flag-inappropriate-menu"
                                   :no-caret="true">
                <template #button-content>
                  <b-icon icon="three-dots-vertical"
                          class="flag-inappropriate__icon"/>
                </template>
                <b-dropdown-item @click="showFlagInappropriateModal" :disabled="classroomExchangeExpired">
                  Flag as Inappropriate
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <flag-inappropriate-modal ref="flag-inappropriate-modal"
                              :classroom-id="classroomId"
                              :submission-id="this.submission.id"
                              @close-flag-inappropriate-modal="$emit('close-flag-inappropriate-modal')"/>
  </b-row>
</template>

<script>
import VideoCustom from '@/core/components/video/VideoCustom'
import FlagInappropriateModal from './FlagInappropriateModal.vue'
import { AppStorage } from '@/core/utils'
import { ClassroomExchangeMixins, CourseMixins } from '@/modules/mixins'

export default {
  name: 'ClassroomDetailItem',
  mixins: [ClassroomExchangeMixins, CourseMixins],
  components: {
    VideoCustom,
    FlagInappropriateModal
  },
  props: {
    submission: {
      type: Object,
      required: true
    },
    hiddenActions: {
      type: Boolean,
      default: false
    },
    disableButtonRespond: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToUploadOrRecordResponsePage (disable) {
      if (disable) return {}
      return {
        name: 'UploadOrRecordPage',
        params: {
          classroomId: this.$route.params.classroomId,
          taskId: this.submission.class_task_id,
          submissionId: this.submission.id
        }
      }
    },
    onSendMessage () {
      this.$emit('send-message', this.submission)
      this.$bvModal.show('text-respond-modal')
    },
    setUserName (name) {
      const isNameWhitComma = !!name.split('').find(char => char === ',')
      let setName
      if (isNameWhitComma) {
        setName = name.split(',')
      } else {
        setName = name.split(' ')
      }
      const lastInitial = setName[1].split('').filter(item => item !== ' ').splice(0, 1).join('')
      return `${setName[0]} ${lastInitial}`
    },
    showFlagInappropriateModal () {
      this.$refs['flag-inappropriate-modal'].showModal()
      this.$refs['video-custom'].pauseVid()
    }
  },
  computed: {
    taskResponseType () {
      return parseInt(this.submission.task.response_type)
    },
    taskResponses () {
      let responsesLength
      let myResponsesLength
      if (typeof this.submission.responses === 'object') {
        responsesLength = Object.keys(this.submission.responses).length
      } else {
        responsesLength = this.submission.responses.length
      }
      if (typeof this.submission.my_responses === 'object') {
        myResponsesLength = Object.keys(this.submission.my_responses).length
      } else {
        myResponsesLength = this.submission.my_responses.length
      }
      return responsesLength + myResponsesLength
    },
    classroomId () {
      return this.$route.params.classroomId
    },
    isTeacher () {
      const user = AppStorage.getUser()
      return user.is_teacher
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.submission-row
  background-color color-purple-100
  padding-top 40px
  max-width 400px
  margin auto
  margin-bottom 40px

  @media (min-width: 767px)
    max-width 600px

.video-container
  background-color #f3f3f3
  margin auto
  padding 0
  @media (min-width: 768px)
    margin auto
    width 490px
    height 720px
  @media (max-width: 767px)
    width 345px
    height 565px

  .video-overlay
    border-radius: 0
    width 100%
    height 100%

.action-container
  word-break break-word
  padding 0 60px
  width 100%
  @media (max-width: 768px)
    padding 0 20px

  .task-name
    font font-opensans-semibold
    text-transform capitalize
    padding 20px 0

  .actions-absolute
    display flex
    margin 0 auto
    @media (min-width: 380px)
      max-width 375px
    @media (min-width: 768px)
      max-width 460px
    @media (min-width: 992px)
      flex-direction column

  .actions
    position relative
    display flex
    justify-content flex-end
    margin-top 5px
    @media (min-width: 992px)
      padding-left 56px
    @media (max-width: 991px)
      text-align right

    .submission-responses-count
      @media (max-width: 991px)
        position absolute
        left 0
        top 0.9rem
        display inline-block
        vertical-align middle
      @media (max-width: 767px)
        top 0.5rem
      @media (max-width: 500px)
        left 10px

    .action-icon
      cursor pointer
      text-decoration none
      background-color color-blue-100
      display flex
      padding 4px 15px
      color color-purple-500
      max-width 200px
      border-radius 15px
      border 2px solid #9aca4d
      align-items center
      @media (max-width: 991px)
        display flex
        align-items center
        vertical-align top
        max-width 160px

        .response-button-text-mobile
          font-size 12px
          padding-right 10px

    .icon-camera, .icon-comment
      color color-black-200
      display inline-block
      order 2

      @media (min-width: 992px)
        order 1
        font-size 32px
        width 50px
      @media (min-width: 768px) and (max-width: 991px)
        font-size 28px
        margin-right 20px
      @media (max-width: 767px)
        font-size 20px
        margin-right 15px

    .icon-camera
      display block
      color color-white-100

    .icon-comment
      margin-top 11px
      font-size 17px
      @media (min-width: 992px)
        font-size 28px
        width 50px
      @media (min-width: 768px) and (max-width: 991px)
        margin-top 19px
        font-size 22px

    .response-button-text, .response-button-text-mobile
      font font-opensans-regular
      width 100%
      text-align center
      color color-white-100
      margin: 0 !important
      order 2
      text-transform uppercase
      overflow hidden
      text-overflow ellipsis
      word-break initial
      @media (min-width: 992px)
        display inline-block
        font-size 15px
        margin-left 0
        vertical-align top
      @media (max-width: 991px)
        display none

    .response-button-text-mobile
      display none
      @media (max-width: 991px)
        display block

  .actions-disabled
    .action-icon
      background-color color-gray-600
      border-color color-gray-600

  .flag-inappropriate
    display flex
    padding-bottom 14px

    .flag-inappropriate__icon
      font-size 20px

    @media (min-width: 992px)
      padding-bottom 37px

      .flag-inappropriate__icon
        font-size 32px

    .flag-inappropriate-menu
      list-style none
      right 8px

      ::v-deep
        .nav-link
          padding 0
          outline none
          color color-black-200

        .dropdown-menu
          left 30px !important
          top -10px !important
          font font-opensans-bold
          @media (max-width: 767px)
            font-size 12px

.information-bg
  background-color color-purple-100
  @media (min-width: 992px)
    order 2

  .information-container
    @media (min-width: 992px)
      margin auto
      color color-black-200
      font-size 12px
      font-weight 600
    @media (max-width: 991px)
      margin auto
      color color-black-200
      font-size 12px
      font-weight 600
      padding-top 7px
      padding-left 0
    @media (max-width: 767px)
      padding-left 22px
      font-size 10px

    .username
      font-weight 800
      @media (min-width: 992px)
        font-size 24px
        margin-bottom 9px
      @media (min-width: 768px) and (max-width: 991px)
        display inline-block
        font-size 14px
      @media (max-width: 767px)
        display inline-block
        font-size 12px

    .posted-text
      @media (min-width: 992px)
        font-size 14px
      @media (min-width: 768px) and (max-width: 991px)
        display inline-block
        font-size 14px
        margin-left 5px
      @media (max-width: 767px)
        display inline-block
        font-size 12px
        margin-left 5px

    .country-text
      @media (min-width: 992px)
        font-size 14px
      @media (min-width: 768px) and (max-width: 991px)
        font-size 14px
        margin-top -2px
      @media (max-width: 767px)
        font-size 12px
        margin-top -2px

    .submission-name-text
      @media (min-width: 992px)
        font-size 18px
        margin-top 12px
      @media (min-width: 768px) and (max-width: 991px)
        font-weight 600
        font-size 14px
      @media (max-width: 767px)
        font-weight 600
        font-size 12px

    .container-reponses
      padding-left 0
      background-color color-purple-100
      text-align left

      .submission-responses-count
        left 0
        font-size 16px
        font-weight 400
        position static
        padding-bottom 20px

        @media (max-width: 992px)
          font-size 14px
          padding-bottom 7px
</style>
